<template>
  <span>
    <v-chip
      label
      light
      color="grey darken-1"
      outlined
      x-small
      v-if="id == transporter.FLASHEXPRESS"
      class="pl-1 pr-1"
    >
      FLASH
    </v-chip>
    <v-chip
      label
      dark
      color="red darken-4"
      x-small
      outlined
      v-else-if="id == transporter.KERRYEXPRESS"
      class="pl-1 pr-1"
    >
      KERRY
    </v-chip>
  </span>
</template>

<script>
import { TRANSPORTER } from "@/services/constants";

export default {
  name: "Transporter",
  props: ["id"], //การกำหนด properties ให้กับ components
  data() {
    return {
      transporter: { ...TRANSPORTER },
    };
  },
  mounted() {
    //console.log(this.status)
  },
};
</script>
