<template>
  <span>
    <v-chip
      label
      dark
      color="red darken-3"
      small class="pl-2 pr-2"
      v-if="status == orderstatus.WAIT"
    >
      รอ
    </v-chip>
    <v-chip
      label
      dark
      color="green"
      small class="pl-2 pr-2"
      v-else-if="status == orderstatus.COMPLETED"
    >
      สำเร็จ
    </v-chip>
    <v-chip
      label
      dark
      color="orange"
      small class="pa-1"
      v-else-if="status == orderstatus.PENDING"
    >
      รอจัดการ
    </v-chip>
    <v-chip label small class="pa-1" v-else-if="status == orderstatus.CANCELLED">
      ยกเลิก
    </v-chip>
    <v-chip
      color="yellow"
      label
      small class="pa-1"
      v-else-if="status == orderstatus.PRINTED"
    >
      PRINTED
    </v-chip>
    <v-chip
      color="purple darken-1"
      dark
      label
      small class="pa-1"
      v-else-if="status == orderstatus.SHIPPING"
    >
      เตรียมส่ง
    </v-chip>
    <v-chip
      color="purple darken-1"
      dark
      label
      small class="pa-1"
      v-else-if="status == orderstatus.KAEXPORTED"
    >
      KA-Exported
    </v-chip>
    <v-chip
      color="blue darken-1"
      dark
      label
      small class="pa-1"
      v-else-if="status == orderstatus.BOOKING"
    >
      KA-Booking
    </v-chip>
    <v-chip
      color="lime"
      light
      label
      small class="pa-1"
      v-else-if="status == orderstatus.KAPRINTED"
    >
      KA-PRINTED
    </v-chip>
    <v-chip
      color="blue lighten-2"
      dark
      label
      small class="pa-1"
      v-else-if="status == orderstatus.PREORDER"
    >
      Pre-Order
    </v-chip>
     <v-chip
      color="orange lighten-3"
      light
      label
      small class="pa-1"
      v-else-if="status == orderstatus.KERRYEXPORTED"
    >
      KERRY-EXPORTED
    </v-chip>
    <v-chip
      color="orange darken-4"
      dark
      label
      small class="pa-1"
      v-else-if="status == orderstatus.KERRYBOOKING"
    >
      KERRY-BOOKING
    </v-chip>
    <v-chip
      color="orange darken-4"
      dark
      label
      small class="pa-1"
      v-else-if="status == orderstatus.KERRYPRINTED"
    >
      KERRY-PRINTED
    </v-chip>
  </span>
</template>

<script>
import { ORDERSTATUS } from "@/services/constants";

export default {
  name: "OrderStatus2",
  props: ["status"], //การกำหนด properties ให้กับ components
  data() {
    return {
      orderstatus: { ...ORDERSTATUS },
    };
  },
  mounted() {
    //console.log(this.status)
  },
};
</script>

<style></style>
