<template>
  <v-container fluid grey lighten-3 pa-10>
    <v-snackbar
      v-model="snackbar.active"
      bottom
      color="yellow darken-2"
      :timeout="3000"
    >
      <v-icon light small class="mr-1">check_circle</v-icon>
      <b class="black--text">{{ snackbar.msg }}</b>
      <v-btn light text @click="snackbar.active = false">
        ปิด
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarFaild.active"
      bottom
      color="error"
      :timeout="3000"
    >
      <v-icon dark small class="mr-1">cancel</v-icon>
      <b>{{ snackbarFaild.msg }}</b>
      <v-btn dark text @click="snackbarFaild.active = false">
        ปิด
      </v-btn>
    </v-snackbar>

    <v-row>
      <v-col md="6" cols="12">
        <h3 class="title mt-5 text-right">KA Booking ::</h3>
      </v-col>
      <v-col md="6" cols="12">
        <v-file-input
          label="File input นามสกุล xlsx เท่านั้น"
          accept=".xlsx"
          prepend-icon="mdi-file-excel"
          type="file"
          @change="handleChange"
        ></v-file-input>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="caption">
        ออร์เดอร์จะ ปริ้น ได้ก็ต่อเมื่อ มีสถานะเป็น KA-Booking แล้วเท่านั้น 
        ** ออเดอร์ที่จะอัพเดตบุ๊คได้ต้องมีสถานะเป็น KA-Exported
      </v-col>
    </v-row>

    <v-card>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="dataTrack"
        item-key="orderid"
        :loading="dataTableLoading"
        hide-default-footer
        disable-pagination
        show-select
        @toggle-select-all="selectAllToggle"
        disable-filtering
        disable-sort
        mobile-breakpoint="200"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="grey lighten-4" light height="70">
            <v-badge size="18" color="yellow accent-4">
              <template v-slot:badge>
                <span class="black--text">{{ selected.length }}</span>
              </template>
              เลือก
            </v-badge>
            <v-divider class="mx-7" inset vertical=""></v-divider>
            {{ totalTracking }} | {{ responseUpdate }}
            <v-spacer></v-spacer>

            <v-btn
              color="yellow darken-1"
              @click="updateStatusToBooking"
              light
              class="mb-1 mr-1"
            >
              <v-icon class="mr-1">mdi-elevator</v-icon>
              อัพเดต KA-Booking
            </v-btn>

            <v-btn
              color="grey darken-3"
              text
              @click="clearData"
              light
              dense
              class="mb-1 mr-1"
            >
              <v-icon>mdi-refresh</v-icon> Clear data
            </v-btn>
            <v-menu subheader>
              <template v-slot:activator="{ on }">
                <v-btn class="mb-1" icon light v-on="on">
                  <v-icon class="ml-1">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-subheader>เมนู</v-subheader>
                <v-list-item @click="printOrder">
                  <v-list-item-icon><v-icon>print</v-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >Print
                      <v-chip color="blue" dark small
                        >KA</v-chip
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader>เปลี่ยน สถานะ</v-subheader>
                <v-list-item
                  v-for="(item, index) in itemsMenuAction"
                  :key="index"
                  @click="doActionMenu(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item, select, isSelected, index }">
          <tr :key="index">
            <td>
              <v-simple-checkbox
                @input="select"
                :value="isSelected"
                :ripple="false"
                v-if="item.status == orderstatus.KAEXPORTED || item.status == orderstatus.BOOKING"
              ></v-simple-checkbox>
            </td>
            <td class="yellow lighten-4">
              {{ item.orderid }}
            </td>
            <td>
              <OrderStatus :status="item.status"></OrderStatus>
            </td>
            <td>{{ item.track }}</td>
            <td>
              {{ item.name }}
            </td>
            <td>
              <v-icon color="blue lighten-2" size="15">mdi-phone</v-icon>
              {{ item.tel }}
            </td>
            <td class="blue lighten-5 caption">
              <span v-if="item.hasTrack" class="green--text"
                ><v-icon small color="green"
                  >mdi-checkbox-marked-circle</v-icon
                ></span
              >
              <span class="red--text" v-else
                ><v-icon small color="red darken-3"
                  >mdi-truck-fast</v-icon
                ></span
              >
              {{ item.message }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

     <v-row class="subtitle-2 mt-5">
      <v-col>
        <b>หมายเหตุ</b><br />
        <ul>
          <li>เมื่อได้ไฟล์ที่ได้จากการบุ๊คกิ้งทางระบบ KA System แล้วให้นำมาอัพโหลดที่นี่ที่ช่อง <b>KA:Booking</b> ด้านบน และอัพเดตสถานะเป็น KA-Booking โดยการกดปุ่ม <v-icon color="yellow darken-4">mdi-elevator</v-icon> ก่อน ทำการปริ้น</li>
        </ul>
        <br/>
        <v-icon color="red" small>warning</v-icon> <b>ในการอัพโหลดไฟล์ครั้งแรก ข้อมูลที่จะปรากฎ</b>
        <ul>
          <li>ช่อง <b>สถานะปัจจุบัน</b> ต้องเป็น <b>KA-Exported</b> เท่านั้น และ <b>ช่อง Tracking ในระบบ (ช่องแถบสีฟ้า)</b> ต้องเป็นค่าว่าง **เป็นการเช็คซ้ำว่าได้อัพโหลดไฟล์ซ้ำหรือป่าว</li>
          <li>เมื่อกดปุ่ม <v-icon color="yellow darken-4">mdi-elevator</v-icon> อัพเดต kA-Booking จะเป็นการอัพเดตเลข Tracking ที่ได้จากไฟล์เข้าสู่ระบบ และ สถานะจะเปลี่ยนเป็น <b>​KA-Booking</b> และช่อง <b>"Tracking ที่มีในระบบ"</b> จะมีเลข Tracking แสดง</li>
        </ul>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmChangeStatusDlg" max-width="290">
      <v-card>
        <v-card-title class="headline">เปลี่ยนสถานะ</v-card-title>
        <v-card-text class="body">
          ต้องการเปลี่ยนสถานะเป็น
          <h3>
            <span class="blue--text">{{ selectedStatusAction.title }}</span>
            ใช่หรือไม่?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmChangeStatusDlg = false"> cancel </v-btn>
          <v-btn color="error" text @click="doChangeStatus">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api, { ORDERID_PREFIX, ORDERSTATUS } from "@/services/api";
import OrderStatus from "@/components/helpers/OrderStatus";
import Exceljs from "exceljs";
import _ from "lodash";

export default {
  name: "KaBooking",
  components: {
    OrderStatus,
  },
  data() {
    return {
      orderstatus: ORDERSTATUS,
      selected: [],
      disabledCount: 0,
      dataTableLoading: false,
      file: "",
      data: [],
      dataTrack: [],
      dataUpadted: [],
      headers: [
        {
          text: "รหัส Order",
          value: "orderid",
          class: "yellow lighten-1",
        },
        { text: "สถานะปัจจุบัน", value: "status" },
        { text: "Tracking ใหม่", value: "track" },
        { text: "Name", value: "name" },
        { text: "Tel", value: "tel" },
        { text: "Tracking ที่มีในระบบ", value: "hasTrack", class: "blue lighten-4" },
      ],
      statusSelect: [
        { id: ORDERSTATUS.WAIT, name: "รอ" },
        { id: ORDERSTATUS.PENDING, name: "รอจัดการ" },
        { id: ORDERSTATUS.PRINTED, name: "PRINTED" },
        { id: ORDERSTATUS.KAEXPORTED, name: "KA-Exported" },
        { id: ORDERSTATUS.BOOKING, name: "KA-Booking" },
        { id: ORDERSTATUS.KAPRINTED, name: "KA-PRINTED" },
        { id: ORDERSTATUS.COMPLETED, name: "สำเร็จ" },
        { id: ORDERSTATUS.CANCELLED, name: "ยกเลิก" },
        { id: ORDERSTATUS.PREORDER, name: "Pre-Order" },
      ],
      itemsMenuAction: [
        { title: "รอ", actionType: ORDERSTATUS.WAIT },
        { title: "รอจัดการ", actionType: ORDERSTATUS.PENDING },
        { title: "PRINTED", actionType: ORDERSTATUS.PRINTED },
        { title: "KA-Exported", actionType: ORDERSTATUS.KAEXPORTED },
        { title: "KA-Booking", actionType: ORDERSTATUS.BOOKING },
        { title: "KA-PRINTED", actionType: ORDERSTATUS.KAPRINTED },
        { title: "สำเร็จ", actionType: ORDERSTATUS.COMPLETED },
        { title: "ยกเลิก", actionType: ORDERSTATUS.CANCELLED },
        { title: "Pre-Order สั่งจอง", actionType: ORDERSTATUS.PREORDER },
      ],
      snackbar: {
        active: false,
        msg: "",
      },
      snackbarFaild: {
        active: false,
        msg: "",
      },
      confirmChangeStatusDlg: false,
      selectedStatusAction: "",
    };
  },
  computed: {
    totalTracking() {
      let total = `ทั้งหมด: ${this.dataTrack.length} | `;
      let orderfounds = _.filter(this.dataTrack, { inOrder: true });
      let totalfound = ` ตรงกับระบบ: ${orderfounds.length}`;
      return `${total} ${totalfound}`;
    },
    responseUpdate() {
      let rPending = _.filter(this.dataTrack, { hasTrack: false });
      let rBooked = _.filter(this.dataTrack, { hasTrack: true });
      return ` รอ book: ${rPending.length} | book แล้ว: ${rBooked.length}`;
    },
  },
  methods: {
    selectAllToggle(props) {
      //console.log(props.value) // toggle is true, false 

       if(this.selected.length != (this.dataTrack.length - this.disabledCount)) {
            this.selected = [];
            const self = this;
            props.items.forEach(item => { // props.items คือ items ที่เราทำการเลือก selected 
              if(item.status == ORDERSTATUS.KAEXPORTED || item.status == ORDERSTATUS.BOOKING) {
                self.selected.push(item);
              } 
            });
        } else {
          this.selected = [];
        } 
    },
    async updateStatusToBooking() {
      if (confirm("คุณต้องการอัพเดตสถานะใช่หรือไม่")) {
        let itemsSelected = this.selected;
        // let itemsSelected = this.$store.getters["itemsSelected"];
        if (itemsSelected) {
          let ids = _.map(itemsSelected, (item) => {
            return { id: item.id, track: item.track };
          });

          let formData = new FormData();
          formData.append("items", JSON.stringify(ids));
          formData.append("status", ORDERSTATUS.BOOKING);
          let result = await api.updateBulkOrderStatusToBooking(formData);
          if (result.data.result == "ok") {
            // update success
            this.snackbar = {
              active: true,
              msg: `อัพเดตสถานะสำเร็จ`,
            };
            this.reloadData();
          } else {
            // update failed
            this.snackbarFaild = {
              active: true,
              msg: `อัพเดตสถานะไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
            };
          }
        }
      }
    },
    doActionMenu(actionType) {
      this.selectedStatusAction = actionType;
      this.confirmChangeStatusDlg = true;
    },
    doChangeStatus() {
      this.changeOrderStatus();
      this.confirmChangeStatusDlg = false;
    },
    async changeOrderStatus() {
      if (this.selected.length > 0) {
        let itemsSelected = this.selected;
        let ids = _.map(itemsSelected, (item) => {
          return item.id;
        });

        let formData = new FormData();
        formData.append("items", JSON.stringify(ids));
        formData.append("status", this.selectedStatusAction.actionType);
        let result = await api.updateBulkOrderStatus(formData);
        if (result) {
          // update success
          this.snackbar = {
            active: true,
            msg: `อัพเดตสถานะสำเร็จ`,
          };

          this.selected = [];
          this.reloadData();
        } else {
          // update failed
          this.snackbarFaild = {
            active: true,
            msg: `อัพเดตสถานะไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
          };
        }
      }
    },
    handleChange(e) {
      // console.log(e);
      // this.file = e.target.files[0];
      this.selected = [];
      if (e) {
        this.file = e;
        this.handleImport();
      } else {
        this.dataTrack.splice(0, this.dataTrack.length);
        this.data.splice(0, this.data.length);
        this.dataTrack = [];
        this.data = [];
      }
    },
    handleImport() {

      this.data.splice(0, this.data.length);
      this.dataTableLoading = true;
      const wb = new Exceljs.Workbook();
      const reader = new FileReader();

      reader.readAsArrayBuffer(this.file);
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then((workbook) => {
          //console.log(workbook, 'workbook instance')
          workbook.eachSheet((sheet, id) => {
            sheet.eachRow((row, rowIndex) => {
              if (rowIndex > 1) {
                let rid = parseInt(row.values[3].replace(ORDERID_PREFIX, ""));
                let dataRow = {
                  indexid: rowIndex,
                  id: rid,
                  realid: rid,
                  orderid: _.trim(row.values[3]),
                  track: _.trim(row.values[4]),
                  tel: _.trim(row.values[10]),
                  name: _.trim(row.values[9]),
                  inOrder: false, // ไว้เช็คค่าในออเดอร์,
                  hasTrack: false,
                  message: "ไม่มีออเดอร์นี้",
                  status: "",
                };
                this.data.push({ ...dataRow });
              }
            });
          });
          this.checkTrackToOrder();
        });
      };
    },
    async checkTrackToOrder() {
      if (this.data.length) {
        this.disabledCount = 0;

        let formData = new FormData();
        formData.append("items", JSON.stringify(this.data));
        let result = await api.getOrdersByIds(formData);
          if (result.data.result == "ok") {
            let resdata = JSON.parse(result.data.message);
            // _.forEach(resdata, (d) => {
            //     if (d.status != ORDERSTATUS.KAEXPORTED) { this.disabledCount+=1; }
            // })
            
            // หาผลรวมของจำนวน ออเดอร์ที่มีสถานะไม่เท่ากับ kaexport และ ไม่เท่ากับ kabooking
            this.disabledCount = _.sumBy(resdata, (d) => {
                return d.status != ORDERSTATUS.KAEXPORTED && d.status != ORDERSTATUS.BOOKING ? 1:0;
            })

            _.delay(() => {
              this.dataTrack = resdata;
              this.dataTableLoading = false;
              
              this.$store.dispatch({
                type: "saveKaBooking",
                items: this.dataTrack,
              });
            }, 1000);
          }
      }
    },
    printOrder() {
      if (this.selected.length > 0) {
        this.$store.dispatch({
          type: "selectItems",
          items: this.selected,
        });
        this.$store.dispatch({
          type: "hideMenu",
          isHide: false,
        });
        this.$router.push(
          `/print-order-tracking/timestamp/` + Math.floor(Date.now() / 1000)
        );
      } else {
        console.log("no value");
      }
    },
    clearData() {
      let itemsUploaded = this.$store.getters["itemsSelected"];
      if (typeof itemsUploaded != "undefined") {
        if (confirm("คุณต้องการยกเลิกข้อมูลใช่หรือไม่")) {
          // this.$store.dispatch({
          //   type: "selectItems",
          //   items: undefined,
          // });
          // this.$store.dispatch({
          //   type: "saveKaBooking",
          //   items: undefined,
          // });
           this.$store.dispatch({
            type: "selectItems",
            items: [],
          });
          this.$store.dispatch({
            type: "saveKaBooking",
            items: [],
          });
          this.dataTrack = [];
        }
      }
    },
    reloadData() {
      this.selected = []
      let kaBookingList = this.$store.getters["kaBooking"];
      if (typeof kaBookingList != "undefined") {
        this.data = kaBookingList;
        this.checkTrackToOrder();
      }
    },
  },
  mounted() {
    this.reloadData();
  },
  beforeDestroy: function() {
    delete this.selected;
    delete this.data;
    delete this.dataTrack;
    delete this.dataUpadted;
    delete this.file;
  },
};
</script>

<style scoped>
.my-select-style {
  width: 130px;
  font-size: 14px;
}
</style>
