export const userLevel = {
    methods: {
        isShow() {
            let level = localStorage.getItem('level');
            return (level == 'admin' || level == 'super')? true : false;
        },
        isLevel(){
            return localStorage.getItem('level') || '';
        }
    },
}