<template>
    <v-card @click="selectItem(item)" outlined height="150">
    <div class="d-flex justify-center">
        <v-img
        :src="item.image | imageUrl"
        max-width="65"
        aspect-ratio="1"
        class="mt-1"
      >
      </v-img>
    </div>
    <v-card-title class="text-caption pl-1 pr-1 pt-2 pb-3">{{item.name}}</v-card-title>    
    <v-card-subtitle class="text-caption mylineheight pa-1">{{item.subname}}</v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: "ProductCard",
  props: ['item'],
  data() {
    return {
      itemSelected:''
    };
  },
  mounted() {
  },
  methods: {
    selectItem(item) {
      this.$emit("selected-item", item);
      
    }
  }
};
</script>
<style>
.mylineheight{ 
  line-height: 1.2em !important;
}
</style>
