<template>
  <div>
    <v-row>
      <!-- Form address -->
      <v-card class="mx-auto mt-2" outlined>
        <v-card-text class="pb-0 pt-0">
          <v-form v-model="valid" ref="form" @submit.prevent="submit">
            <v-row class="pa-2">
              <v-col
                md="6"
                cols="12"
                class="pl-md-5 pr-md-5 pl-1 pr-5"
                style="border-right:1px solid #E0E0E0;"
              >
                <v-row>
                  <v-col cols="12" class="pl-1 pr-1 pb-1">
                    <v-select
                      :items="branches"
                      v-model="branchSelected"
                      item-text="name"
                      item-value="id"
                      :rules="[(v) => !!v.id || 'กรุณาเลือก']"
                      dense
                      id="branches"
                      @change="selectBranch"
                      prefix="ผู้ส่ง"
                    >
                      <template v-slot:item="{ item }">
                        <v-avatar size="24" class="mr-5">
                          <v-img
                            v-if="item.image"
                            :src="item.image | imageUrl"
                          ></v-img>
                        </v-avatar>
                        <span>{{ item.name }}</span>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" class="pa-1">
                    <v-textarea
                      v-model.trim="address_info3"
                      placeholder="ชื่อ ที่อยู่"
                      auto-grow
                      rows="9"
                      dense
                      id="address_info"
                      :rules="[(v) => !!v || 'กรุณากรอก']"
                      disable-lookup
                      @blur="detect_address"
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12" class="pa-1">
                    <v-autocomplete
                      v-model="receiverData.address_info2"
                      :items="provinceList"
                      :value="postcode"
                      item-value="name"
                      item-text="name"
                      item-color="blue"
                      color="purple"
                      :rules="[(v) => !!v || 'กรุณากรอก']"
                      placeholder="ต. / อ. / จังหวัด / ปณ "
                      id="address_info2"
                      dense
                      prepend-icon="location_on"
                      disable-lookup
                      :search-input="postcode"
                      :filter="customFilterAutocomplete"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="6" class="pl-1 pr-1 pt-5">
                    <v-text-field
                      v-model="receiverData.tel1"
                      :rules="[telRules.required, telRules.counter]"
                      placeholder="โทร"
                      id="tel1"
                      maxlength="13"
                      dense
                      prepend-icon="phone"
                      v-mask="mask"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pl-1 pr-1 pt-5">
                    <v-text-field
                      v-model="receiverData.tel2"
                      :rules="[telRules.counter]"
                      placeholder="เบอร์ สำรอง"
                      id="tel2"
                      maxlength="13"
                      dense
                      v-mask="mask"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col md="6" class="pl-md-7 pr-md-3 pl-2 pr-2">
                <v-row class="pt-5">
                  <v-col cols="8" class="pa-1">
                    <v-select
                      :items="paymentsMethods"
                      v-model="selectedPaymentMethod"
                      item-text="name"
                      item-value="id"
                      label="ประเภทชำระเงิน"
                      :rules="[(v) => !!v || 'กรุณาเลือก']"
                      :background-color="paymentStyle.bgColor"
                      :dark="paymentStyle.theme"
                      id="paymentsMethods"
                      filled
                      item-color="blue"
                      @change="selectPaymentMethod"
                    ></v-select>
                  </v-col>

                  <v-col cols="4" class="pa-1">
                    <v-text-field
                      v-model.number="receiverData.amount"
                      type="number"
                      label="ยอด"
                      id="amount"
                      suffix="฿"
                      min="0"
                      outlined
                      @focus="$event.target.select()"
                      @blur="change_amount"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="pa-1">
                    <v-select
                      v-model="bankinfo"
                      :items="banks"
                      item-text="name"
                      label="ธนาคาร"
                      item-value="id"
                      :rules="[(v) => !!v.account_name || 'ธนาคารที่โอน']"
                      id="banks"
                      outlined
                      :hint="
                        `${bankinfo.bank_match} | เลข บช. ${bankinfo.account_no}`
                      "
                      persistent-hint
                      return-object
                      @change="selectBank"
                    >
                      <template v-slot:item="{ item }">
                        <v-avatar size="24" class="mr-2">
                          <v-img
                            v-if="item.image"
                            :src="item.image | imageUrl"
                          ></v-img>
                        </v-avatar>
                        <span>{{ item.name }} [ {{ item.account_no }} ]</span>
                      </template>

                      <template v-slot:selection="{ item }">
                        <v-avatar size="24" class="mr-2">
                          <v-img
                            v-if="item.image"
                            :src="item.image | imageUrl"
                          ></v-img>
                        </v-avatar>
                        <span>{{ item.name }}</span>
                      </template>

                      <template v-slot:message="{ key, message }">
                        <span class="brown--text text--darken-4 caption" :key="key">{{
                          message
                        }}</span>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" class="pl-1 pr-1 pt-2 mb-0">
                    <v-text-field
                      v-model.trim="receiverData.remark_product"
                      :counter="100"
                      maxlength="100"
                      label="สินค้าอื่นๆ ระบุสั้นๆ"
                      id="remark_product"
                      dense
                    ></v-text-field>
                  </v-col>


                  <v-col cols="12" class="pl-1 pr-1 pt-2 mb-0" md="6">
                    <v-text-field
                      v-model.trim="receiverData.remark_customer"
                      :counter="120"
                      maxlength="120"
                      label="หมายเหตุ ลูกค้า"
                      id="remark_customer"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="pl-1 pr-1 pt-2 mb-0" md="6">
                    <v-text-field
                      v-model.trim="receiverData.remark_admin"
                      :counter="120"
                      maxlength="120"
                      label="หมายเหตุ admin"
                      id="remark_admin"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="pa-1">
                    <v-combobox
                      v-model="selecttags"
                      :items="tagitems"
                      placeholder="tag ติดตาม"
                      multiple
                      chips
                      small-chips
                      flat
                      :disable-lookup="true"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                 <v-col>
                    <!-- <v-spacer></v-spacer> -->
                  <v-btn color="gray" small class="mr-4" @click="reset"
                    >ล้างข้อมูล</v-btn
                  > <v-btn :disabled="!valid" small @click="selectTransport(transporter.KERRYEXPRESS)" class="mr-2" dark color="orange darken-3" type="button"
                    ><v-icon left>check_circle</v-icon>Kerry</v-btn
                  >
                  <v-btn :disabled="!valid" small @click="selectTransport(transporter.FLASHEXPRESS)" color="yellow darken-1" type="button"
                    ><v-icon left>check_circle</v-icon>Flash</v-btn
                  >
                 </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-snackbar
              v-model="snackbarFoundProduct.active"
              color="cyan lighten-1"
              :timeout="1000"
            >
              <v-icon dark small>mdi-lightbulb-on-outline</v-icon>
              <b class="white--text"> {{ snackbarFoundProduct.msg }} </b>
              <v-btn dark text @click="snackbarFoundProduct.active = false">
                ปิด
              </v-btn>
            </v-snackbar>

            <v-snackbar
              v-model="snackbar.active"
              bottom
              color="amber lighten-2"
              :timeout="2000"
              light
            >
              <v-icon small class="mr-1" left>check_circle</v-icon>
              <b>{{ snackbar.msg }}</b>

              <template v-slot:action="{ attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  @click="snackbar.active = false"
                >
                  ปิด
                </v-btn>
              </template>
            </v-snackbar>


            <v-snackbar
              v-model="snackbarFaild.active"
              bottom
              color="error"
              :timeout="2000"
            >
              <v-icon dark small>cancel</v-icon>
              <b> {{ snackbarFaild.msg }} </b>
              <template v-slot:action="{ attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  @click="snackbarFaild.active = false"
                >
                  ปิด
                </v-btn>
              </template>
            </v-snackbar>
          </v-form>
        </v-card-text>
      </v-card>
      <!-- end Form -->
    </v-row>

    <!-- alert if exists -->
    <v-row justify="center">
      <v-dialog v-model="dialog_exist" persistent max-width="320">
        <v-card loading="warning" loader-height="6">
          <v-card-title class="title"
            >มีออเดอร์ใกล้เคียง
            <span
              class="red--text text--darken-2 pl-1"
              v-if="order_exist.createdAt"
              >{{ order_exist.createdAt | moment("from") }}</span
            >
          </v-card-title>
          <v-card-text class="grey--text text--darken-3">
            รหัส: {{ order_exist.id }} - {{ order_exist.name }}<br />
            วันที่
            <span v-if="order_exist.createdAt">{{
              order_exist.createdAt | moment("DD-MM-YYYY HH:mm")
            }}</span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-4" text @click="dialog_exist = false"
              >ปิด</v-btn
            >
            <v-btn
              color="green darken-4"
              text
              @click="viewOrderExist(order_exist.id)"
              >ดูรายละเอียด</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- bottom sheet component -->
    <OrderDetailSheet
      :id="orderIdSheet"
      @clear-id="orderIdSheet = ''"
    ></OrderDetailSheet>
    <!-- end bottom sheet component -->
  </div>
</template>

<script>
import OrderDetailSheet from "@/components/OrderDetailSheet";
import { mask } from "vue-the-mask";
import api, { ORDERSTATUS, PAYMENTMETHOD, LIVEORDERSTATUS, TRANSPORTER } from "@/services/api";
import _ from "lodash";
import districtsJSON from "@/assets/subdistrict-clean.json";

export default {
  name: "FormCard",
  props: ["items", "products", "liveorderselected"],
  components: {
    OrderDetailSheet,
  },
  directives: {
    mask,
  },
  data() {
    return {
      postcode: "",
      branchSelected: { id: "" },
      orderIdSheet: "",
      order_exist: "",
      dialog_exist: false,
      selecttags: [],
      tagitems: [],
      snackbar: {
        active: false,
        msg: "",
      },
      snackbarFaild: {
        active: false,
        msg: "",
      },
      snackbarFoundProduct: {
        active: false,
        msg: "",
      },
      provinceList: [],
      itemList: [],
      branches: [],
      banks: [],
      bankinfo: { id: "", account_name: "", account_no: "", bank_match: "" },
      mask: "###-###-###-#",
      paymentStyle: { bgColor: "", theme: false },
      telRules: {
        required: (v) => !!v || "กรุณากรอก",
        counter: (v) => {
          if (typeof v != "undefined" && v != "" && v != null) {
            return (v.length >= 11 && v.length <= 13) || "กรอกให้ถูกต้อง";
          } else {
            return true;
          }
        },
      },
      bankRules: {
        required: (v) => {
          if (this.receiverData.paymentMethod == PAYMENTMETHOD.TRANFER) {
            return "กรุณาเลือก";
          } else {
            return true;
          }
        },
      },
      paymentsMethods: [
        { id: PAYMENTMETHOD.TRANFER, name: "โอนเงิน" },
        { id: PAYMENTMETHOD.COD, name: "COD จ่ายปลายทาง" },
      ],
      selectedPaymentMethod: { id: "", name: "" },
      valid: true,
      receiverData: {
        name: "",
        address_info: "",
        address_info2: "",
        tel1: "",
        tel2: "",
        paymentMethod: 0,
        amount: "",
        remark_customer: "",
        remark_admin: "",
        remark_product: "",
        ref_branch_id: 0,
        ref_user_id: 0,
        ref_bank_id: "",
        transporter: 1,
      },
      address_info3: "",
      customer_name: "",
      product_detect: [],
      transporter: {...TRANSPORTER}
    };
  },
  computed: {},
  methods: {
    selectTransport: _.debounce(function(val){
      this.receiverData.transporter = val;
      this.submit();
    }, 300),
    detect_address() {
      this.detectTel();
    },
    change_amount(val) {
      this.$emit("change-amount", this.receiverData.amount || 0);
    },
    detectTel() {
      if (
        this.address_info3 != "" &&
        typeof this.address_info3 != "undefined"
      ) {
        try {
            // console.log(this.receiverData.tel1, this.receiverData.amount);
          if (
            this.receiverData.tel1 == "" ||
            this.receiverData.tel1 == null || 
            typeof this.receiverData.tel1 == "undefined" || 
            this.receiverData.amount == "" ||
            this.receiverData.amount == null || 
            typeof this.receiverData.amount == "undefined" 
            
          ) {
            let address_in = this.address_info3.trim();

            // detect payemnt
            const patternDelPrefixphone = /โทร|เบอร์|มือ|ถือ|tel|mo|bile|phone|ศัพท์|สับ|ศับ|ศัพ|ติด|ต่อ|ตอ|เบอร|เบอ|\(|\)|\.|\:|\-|\s+/gi;
            let splitted = address_in.split(/\r?\n/g);
            splitted = _.compact(splitted);
            let splited_new = _.map(splitted, (d, i) => {
              if (i == 0) {
                this.customer_name = d;
              }
              let check_tel = d.replace(patternDelPrefixphone, "");
              if (/(0\d{9})$/.test(check_tel)) {
                // detect phone
                let tel_match = check_tel.match(/(0\d{9})$/);
                let tel_mask =
                  tel_match[1].slice(0, 3) +
                  "-" +
                  tel_match[1].slice(3, 6) +
                  "-" +
                  tel_match[1].slice(6, 9) +
                  "-" +
                  tel_match[1].slice(9, 10);
                if (
                  this.receiverData.tel1 == "" || 
                  this.receiverData.tel1 == null || 
                  typeof this.receiverData.tel1 == "undefined"
                ) {
                  this.receiverData.tel1 = tel_mask;
                } else if (
                  this.receiverData.tel2 == "" ||
                  this.receiverData.tel2 == null || 
                  typeof this.receiverData.tel2 == "undefined"
                ) {
                  this.receiverData.tel2 = tel_mask;
                }
                let data_new = check_tel.replace(tel_match[1], "");
                return data_new;
              } else {
                // detect payment
                if (d.search(/cod|ปลายทาง/i) >= 0) {
                  this.detectPayment(d, PAYMENTMETHOD.COD);
                  return "";
                } else if (d.search(/โอน/) >= 0) {
                  this.detectPayment(d, PAYMENTMETHOD.TRANFER);
                  return "";
                } else if (d.search(/credit|pay|เพพาว/) >= 0) {
                  this.detectPayment(d, PAYMENTMETHOD.TRANFER);
                  return "";
                } else if (/(\d{5})/.test(d)) {
                  let postcode = d.match(/(\d{5})/);
                  try {
                    this.postcode = postcode[1];
                    return d;
                  } catch {
                    console.log("detect postcode failed!");
                  }
                } else {
                  //detect product
                  if (i > 2) {
                    this.detectProduct(d);
                    return d;
                  } else {
                    return d;
                  }
                }
              }
            });

            splitted = null;
            splited_new = _.compact(splited_new);
            address_in = splited_new.join("\r\n");
            splited_new = null;
            this.address_info3 = address_in;
          } else {
            let address_in = this.address_info3.trim();
            let splitted = address_in.split(/\r?\n/g);
            splitted = _.compact(splitted);
            if (splitted[0]) {
              this.customer_name = splitted[0];
            }
            splitted = null;
          }

          // emit product to billing component
          this.emitToDetectProduct();
        } catch (error) {
          console.log("automation address failed: ", error.message);
        }
      }
    },
    emitToDetectProduct() {
      if (this.product_detect.length > 0) {
        this.$emit("detect-item", this.product_detect);
        this.snackbarFoundProduct = {
          active: true,
          msg: "จับคู่สินค้าอัตโนมัติ",
        };
        this.product_detect = [];
      }
    },
    detectProduct(data) {
      if (data != "") {

        function searchLabelPrd(product) {
          if (product.label_search) {
            let psplit = product.label_search.toLowerCase().replace(/\s/g, '').split(","); // replace white space ช่องว่าง
            let rindex_find = _.findIndex(psplit, (c) => {
              return d_lower == c;
            });
            return rindex_find >= 0 ? true:false;
          } else {
            return false;
          }
        }

        let initProduct = { prd: "", qty: 1 };
        let raw_data = data.toLowerCase();
        let d_lower = raw_data.replace(/\s/g, '');
        let prdFilter = _.find(this.products, (p) => {
          return searchLabelPrd(p);
        });

        if (typeof prdFilter != "undefined") {
          // เป็นการเช็คตรงทุกตำแหน่ง
          // ค้นหาเจอตรงทั้งหมด **ทำไมถึงใส่ qty 1 ตัวอย่าง ข้อมูลดิบเป็น "คอลลาเจน ใหญ่ 4" ความหมายก็คือชุดโปรใหญ่นั้นเอง เลยใช่จำนวน 1 หรือ ข้อมูลดิบเป็น "ทอง ญ" ไม่ได้ระบุจำนวน
          initProduct = { prd: prdFilter, qty: 1 };
        } else {
          // กรณีค้นหาไม่เจอ แยกคำ และ จำนวนออกจากกัน แล้วค้นหาอีกรอบ แสดงว่าเป็นสินค้าเดี่ยวแยกชิ้นไม่ได้จัดโปร
         
          let d_array = raw_data.split(" ");
          d_array = _.compact(d_array);
          let qty = d_array[d_array.length - 1];
          qty = parseInt(qty);
          if (Number.isInteger(qty)) {
            d_array = _.dropRight(d_array);
          } else {
            qty = 1;
          }

          d_lower = d_array.join("").trim();
          let prdFilter = _.find(this.products, (p) => {
            return searchLabelPrd(p);
          });
          if (prdFilter) {
            initProduct = { prd: prdFilter, qty: qty };
          }
        }

        if (initProduct.prd != "") {
          this.product_detect.push(initProduct);
        }
      }
    },
    detectPayment(textpayinfo, type) {
      let amount = textpayinfo.match(/\d+/g);
      if (amount != null) {
        this.receiverData.amount = amount[0];
        this.selectedPaymentMethod = { id: type };
        this.selectPaymentMethod(type);
        textpayinfo = textpayinfo.toLowerCase().replace(/\s/g, '');
        let bank_match = '';

        function searchNameBank(bank) {
          if (bank.account_name) {
            let psplit = bank.account_name.toLowerCase().replace(/\s/g, '').split(","); // replace white space ช่องว่าง
            let rindex_find = _.findIndex(psplit, (c) => {
              if (textpayinfo.search(c) > -1) {
                bank_match = c;
                return true;
              } else {
                bank_match = '';
                return false;
              }
            });
            return rindex_find >= 0 ? true:false;
          } else {
            return false;
          }
        }

         let bankFilter = _.find(this.banks, (b) => {
          return searchNameBank(b);
        });

       if (typeof bankFilter != 'undefined') {
         this.receiverData.ref_bank_id = bankFilter.id;
            this.bankinfo = {
              id: bankFilter.id,
              account_name: bankFilter.account_name,
              account_no: bankFilter.account_no,
              bank_match: bank_match
            }
          }
       

        // _.forEach(this.banks, (b) => {
        //   if (textpayinfo.search(b.account_name.toLowerCase()) >= 0) {
        //     this.receiverData.ref_bank_id = b.id;
        //     this.bankinfo = {
        //       id: b.id,
        //       account_name: b.account_name,
        //       account_no: b.account_no,
        //     };
        //   }
        // });
      }
    },
    customFilterAutocomplete(item, queryText, itemText) {
      if (queryText != "") {
        let text = item.name;
        let searchTxt = _.trim(queryText);
        if (searchTxt.length > 2) {
          return text.indexOf(searchTxt) > -1;
          //return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async submit() {
      // console.log("user info", this.receiverData);
      // console.log("item", this.itemList);

      this.receiverData.ref_user_id = this.$store.getters["userid"];
      //this.$emit("completed_order", true, this.receiverData, this.itemList);
      let splitted = this.address_info3.split(/\r?\n/g);

      if (this.$refs.form.validate()) {
        let name = splitted[0];
        splitted.splice(0, 1);
        let newaddress = _.compact(splitted); // ลยข้อมูล array ที่ไม่มีค่า ออก
        let address = newaddress.join("\r\n");
        let formData = new FormData();
        formData.append("name", name);
        formData.append("address_info", address);
        formData.append("address_info2", this.receiverData.address_info2);
        formData.append("tel1", this.receiverData.tel1);
        formData.append("tel2", this.receiverData.tel2 || "");
        formData.append("paymentMethod", this.receiverData.paymentMethod);
        formData.append("amount", this.receiverData.amount || 0);
        formData.append(
          "remark_customer",
          this.receiverData.remark_customer || ""
        );
        formData.append("remark_admin", this.receiverData.remark_admin || "");
        formData.append("remark_product", this.receiverData.remark_product || "");
        formData.append("ref_branch_id", this.receiverData.ref_branch_id);
        formData.append("ref_bank_id", this.receiverData.ref_bank_id);
        formData.append("ref_user_id", this.$store.getters["userid"]);
        formData.append("user_id", this.$store.getters["userid"]);
        formData.append("items", JSON.stringify(this.itemList));
        formData.append("status", ORDERSTATUS.WAIT);
        formData.append("multitags", JSON.stringify(this.selecttags));
        formData.append("transporter", this.receiverData.transporter);
        //console.log(this.itemList)

        let result = await api.addOrder(formData);
        //console.log(result);
        if (result.data.result == "ok") {
          let item = JSON.parse(result.data.message);
          let orderExist = JSON.parse(result.data.orderlasted);
          if (orderExist != null) {
            _.delay(() => {
              this.order_exist = orderExist;
              this.dialog_exist = true;
              orderExist = null;
            }, 1000);
          }

          // update order_id = lasted_id, status = completed to liveorder
           if (this.liveorderselected.id) {
              let formDataLive = new FormData();
              formDataLive.append('status', LIVEORDERSTATUS.COMPLETED)
              formDataLive.append('ref_order_id', item.id)
              formDataLive.append('id', this.liveorderselected.id)
              await api.updateLiveOrderStatus(formDataLive)
              this.liveorderselected.id = '';
           }

          this.$emit("completed_order", { orderId: item.id, name: item.name });
          this.snackbar = {
            active: true,
            msg: `${item.name} สำเร็จ`,
          };

          this.reset();
        } else {
          this.snackbarFaild = {
            active: true,
            msg: `ไม่สามารถบันทึกข้อมูลได้`,
          };
        }
        // this.$router.push("/category");
      }
    },
    selectBank(item) {
      this.receiverData.ref_bank_id = item.id;
    },
    selectBranch(id) {
      this.branchSelected = { id };
      this.receiverData.ref_branch_id = id;
      let c = _.find(this.branches, { id });
      this.$emit("selected_branch", c.name);
    },
    selectPaymentMethod(id) {
      if (id == PAYMENTMETHOD.TRANFER) {
        this.paymentStyle.bgColor = "light-blue lighten-5";
        this.paymentStyle.theme = false;
      } else if (id == PAYMENTMETHOD.COD) {
        this.paymentStyle.bgColor = "yellow";
        this.paymentStyle.theme = false;
      }

      this.receiverData.paymentMethod = id;
    },
    viewOrderExist(id) {
      this.orderIdSheet = id;
    },
    reset() {
      this.itemList = [];
      this.postcode = "";
      this.paymentStyle.bgColor = "";
      this.paymentStyle.theme = false;
      this.$refs.form.reset();
      this.bankinfo = { account_name: "", account_no: "", bank_match: "" };
      this.receiverData.ref_bank_id = "";

      this.branchSelected = { id: this.receiverData.ref_branch_id };
      this.selecttags = [];
      this.$emit("clear-items", true);
      this.$emit("clear-liveorder", true);
    },
  },
  async mounted() {
    let result = await api.getBranchActive();
    this.branches = result.data;
    this.provinceList = districtsJSON;
    let bankResult = await api.getBankActive();
    this.banks = bankResult.data;

    // get tags
    let result_tags = await api.getTagAll();
    if (result_tags.data.result == "ok") {
      this.tagitems = JSON.parse(result_tags.data.message);
    }

    console.log("mounted in formcard");
  },
  watch: {
    liveorderselected: function(val) {
      this.address_info3 = val.detail;
    },
    items: {
      //from props
      handler(newVal, oldVal) {
        this.itemList = newVal;
      },
      deep: true,
    },
    products: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          if (this.address_info3) {
            let address_in = this.address_info3.trim();
            let splitted = address_in.split(/\r?\n/g);
            splitted = _.compact(splitted);
            _.forEach(splitted, (d, i) => {
              this.detectProduct(d);
            });
            this.emitToDetectProduct();
          }
        }
      },
      deep: true,
    },
    valid: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.$emit("valid-form", {
            valid: newVal,
            amount: this.receiverData.amount,
            customer_name: this.customer_name,
          });
        }
      },
    },
    customer_name: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.$emit("valid-form", {
            valid: this.$refs.form.validate(),
            amount: this.receiverData.amount,
            customer_name: newVal,
          });
        }
      },
    },
  },
  beforeDestroy: function() {
    delete this.selecttags;
    delete this.tagitems;
    delete this.provinceList;
    delete this.itemList;
    delete this.branches;
    delete this.banks;
    delete this.receiverData;
    delete this.product_detect;
  },
};
</script>

<style></style>
