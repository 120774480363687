<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center">
        <v-btn class="mt-2" text color="red" @click="sheet = !sheet"
          >close</v-btn
        >
        <div class="py-1">
          <v-container class="pt-0">
            <v-row class="justify-center subtitle-2"
              >
                <v-col class="pa-0 mt-0">
                  รหัสสั่งซื้อ {{ data.id }} <span v-if="data.createdAt" class="body-2"> : {{data.createdAt | moment("DD-MM-YYYY H:m")}}</span>
                </v-col>
              </v-row
            >
            <v-row class="justify-center body-2" v-if="data.tracking">
               <v-col class="pa-0 mb-1 mt-1">
                 <span class="caption">{{data.tracking}}</span> <span class="caption" v-if="data.tracking_date"> : {{data.tracking_date | moment("DD-MM-YYYY H:m")}}</span> 
                 | <span class="caption">ค่าส่ง: {{data.shipping_cost}}</span> : <span class="caption">COD.fee: {{data.cod_cost}}</span> 
               </v-col>
            </v-row>
            <v-row class="subtitle-2 grey--text pl-2">รายละเอียด</v-row>
            <v-row class="text-left">
              <v-col md="6" class="body-2">
                <h4 class="subtitle-2"><b>ผู้รับ</b></h4>
                {{ data.name }}<br />
                {{ data.address_info }} {{ data.address_info2 }}<br />
                โทร: {{ data.tel1 }} , {{ data.tel2 }}
              </v-col>
              <v-col md="6" style="border-left:1px solid #ff9494;">
                <p class="subtitle-2 grey--text mb-2">ยอดรวม</p>
                <p>{{ data.amount | currency("฿", 0) }}
                  <v-avatar size="24" class="mr-1 ml-2">
                    <v-img
                      v-if="bankinfo.image"
                      :src="bankinfo.image | imageUrl"
                    ></v-img>
                  </v-avatar>
                  <span class="subtitle-2"
                    >{{ bankinfo.name }} [ {{ bankinfo.account_no }} ]
                  </span>
                </p>
                <p class="subtitle-2 grey--text mb-2">หมายเหตุ ลูกค้า</p>
                <p>{{ data.remark_customer }}</p>
                <p class="subtitle-2 grey--text mb-2">หมายเหตุ admin</p>
                <p>{{ data.remark_admin }}</p>
                <p class="subtitle-2 grey--text mb-2">หมายเหตุ สินค้าอื่นๆ</p>
                <p>{{ data.remark_product }}</p>
              </v-col>
            </v-row>

            <v-row class="subtitle-2 grey--text pl-2">รายการสินค้า</v-row>

            <v-row v-if="itemList.length > 0" class="mt-5">
              <v-col
                md="4"
                class="pa-1"
                v-for="(item, index) in itemList"
                :key="index"
              >
                <v-card v-if="item.name" outlined>
                  <v-list-item>
                    <v-img
                      :src="item.image | imageUrl"
                      aspect-ratio="1"
                      max-height="70"
                      max-width="70"
                      class="ma-1"
                    ></v-img>

                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2 mb-1 "
                        ><v-icon small color="blue darken-2"
                          >check_circle</v-icon
                        >
                        {{ item.name }}<br />จำนวน:
                        {{ item.qty }}</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        item.subname
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
               <v-col class="caption" v-if="liveorder.detail">
                        <v-chip color="pink" label x-small dark><b>Live id# {{liveorder.id}}</b></v-chip> : 
                        {{liveorder.detail}}<br/><b>Live order</b>: {{liveorder.createdby}}
                      </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading.. please wait
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/services/api";

export default {
  name: "OrderDetailSheet",
  props: ["id"],
  components: {},
  data() {
    return {
      liveorder: {id: '', detail: '', createdby: ''},
      sheet: false,
      dialogLoading: false,
      bankinfo: "",
      data:{},
      itemList: [],
    };
  },
  methods: {
    getData() {
      this.getDataBill();
      this.sheet = true;
      this.$emit("clear-id", true);
    },
    async getDataBill() {
      this.dialogLoading = true;
      let result = await api.getOrder(this.id);

      if (typeof result.data.order !== 'undefined') {
        if (result.data.order.tel1 != "") {
          let tel = result.data.order.tel1;
          let tel1 =
            tel.slice(0, 3) + "-" + tel.slice(3, 6) + "-" + tel.slice(6, 9);
          if (tel.length == 10) {
            tel1 += "-" + tel.slice(9, 10);
            result.data.order.tel1 = tel1;
          }
        }

        if (result.data.order.tel2 != "") {
          let tel = result.data.order.tel2;
          let tel2 =
            tel.slice(0, 3) + "-" + tel.slice(3, 6) + "-" + tel.slice(6, 9);
          if (tel.length == 10) {
            tel2 += "-" + tel.slice(9, 10);
            result.data.order.tel2 = tel2;
          }
        }

        this.itemList = result.data.items;
        this.data = { ...result.data.order };

        if (this.data.ref_bank_id) {
          let result = await api.getBankById(this.data.ref_bank_id);
          this.bankinfo = { ...result.data };
        }

        let rliveorder = await api.getLiveOrderByOrderId(this.data.id)
        if (rliveorder.data.result == 'ok') {
          let rlo = JSON.parse(rliveorder.data.message);
          let rusercreated = await api.getUserById(rlo.ref_user_id);
          this.liveorder = {id: rlo.id, detail: rlo.detail, createdby: rusercreated.data.username}
        } else {
          this.liveorder = {};
        }

        this.dialogLoading = false;
      } else {
        this.dialogLoading = false;
         // token is expired
        this.$router.push('/logout');
      }
      result = null;
    },
  },
  mounted() {},
  watch: {
    id: function(val) {
      if (val != "") {
        this.getData();
      }
    },
  },
  beforeDestroy: function() {
    delete this.data;
    delete this.itemList;
    delete this.liveorder;
  }
};
</script>

<style></style>
